import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { colors } from 'app/shared/styles/_colors';

import cx from 'classnames';
import { get } from 'lodash';
import omit from 'lodash/omit';
import constants from 'app/shared/constants/ValueConstants';
import IconX from 'images/icons/x.svg';
import Label from 'app/shared/core/Label';
import * as S from 'app/shared/core/Input/styles';
import Text from 'app/shared/core/Text';

const StyledAlertText = styled(Text)`
  color: ${colors['$hpx-red-400']};
`;

export type InputSize = 'sm' | 'md' | 'lg' | 'xl';
type InputBaseProps = Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>;

interface InputProps extends InputBaseProps {
  autoFocus?: boolean;
  border?: boolean;
  borderRadius?: boolean;
  centerText?: boolean;
  className?: string;
  customTagClassName?: string;
  disabled?: boolean;
  fixedSize?: boolean;
  focusInput?: boolean;
  help?: string;
  hpxStyle?: string;
  inactive?: boolean;
  includeClear?: boolean;
  includeCancel?: boolean;
  inputButtonGroupPosition?: string;
  isActive?: boolean;
  name?: string;
  onCancel?: () => void;
  onClear?: () => void;
  label?: string | React.ReactNode;
  required?: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  type?: string;
  width?: string;
  id?: string;
  value?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

// Using a different syntax for forwardRef to avoid Babel parsing issues
const Input = React.forwardRef(function Input(props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const {
    autoFocus,
    border = true,
    borderRadius = false,
    centerText = false,
    className = '',
    customTagClassName = null,
    disabled = false,
    fixedSize = false,
    focusInput = false,
    help = '',
    hpxStyle = '',
    inactive = false,
    includeClear = false,
    includeCancel = false,
    inputButtonGroupPosition = null,
    isActive = false,
    name,
    onCancel = () => {},
    onClear = () => {},
    label = '',
    required = false,
    size = 'md',
    type = 'text',
    width = '',
    id,
    value,
    maxLength,
    onKeyDown,
    ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  // Use the forwarded ref if provided, otherwise use our local ref
  const resolvedRef = ref || inputRef;

  useEffect(() => {
    if (focusInput) {
      focusInputFn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusInputFn = () => {
    if (resolvedRef && 'current' in resolvedRef && resolvedRef.current) {
      resolvedRef.current.focus();
    }
  };

  const handleClear = () => {
    onClear();
    focusInputFn();
  };

  const handleClearKeyDown = (event: React.KeyboardEvent) => {
    const { which } = event;
    if (which === constants.ENTER_KEYCODE || which === constants.SPACE_KEYCODE) {
      event.stopPropagation();
      handleClear();
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleCancelKeyDown = (event: React.KeyboardEvent) => {
    const { which } = event;
    if (which === constants.ENTER_KEYCODE || which === constants.SPACE_KEYCODE) {
      event.stopPropagation();
      handleCancel();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = e.which;
    /*
     * necessary for any component using Input that has also has a listener on ESC key because without
     * calling onKeyDown explicitly, ESC would blur the input and the event would not bubble up
     */
    if (keyCode === constants.ESC_KEYCODE) {
      e.currentTarget.blur();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const CustomTag = type === 'textarea' ? 'textarea' : 'input';
  let paddingRight = null;
  if (isActive) {
    if (includeClear && includeCancel && value) {
      paddingRight = 'lg';
    } else if (includeClear || includeCancel) {
      paddingRight = 'md';
    }
  }

  const errorMessage = maxLength && value && value.length >= maxLength ? 'Maximum character limit reached' : help;
  const errorId = id ? id + '-error' : '';

  const ariaDescribedBy = [errorId, get(props, 'aria-describedby', '')].filter((str) => str !== '');

  const filteredProps = omit(rest, [
    'border',
    'borderRadius',
    'centerText',
    'customTagClassName',
    'dispatch',
    'fixedSize',
    'focusInput',
    'help',
    'hpxStyle',
    'inactive',
    'includeCancel',
    'includeClear',
    'inputButtonGroupPosition',
    'isActive',
    'label',
    'onCancel',
    'onClear',
    'size',
    'width',
    'aria-describedby',
    // type=textarea is not valid for <textarea> elements
    ...(type === 'textarea' ? ['type'] : []),
  ]);

  return (
    <div className={cx('Input', className)}>
      {label && (
        <Label {...(id && { htmlFor: id })}>
          {required && <S.InputRequiredMark aria-hidden="true">{'* '}</S.InputRequiredMark>}
          {label}
          {!required && ' (optional)'}
        </Label>
      )}
      {includeClear || includeCancel ? (
        <S.InputContainer>
          <S.Input
            autoFocus={autoFocus}
            maxLength={maxLength}
            name={name}
            required={required}
            disabled={disabled}
            type={type}
            value={value}
            border={border}
            borderRadius={borderRadius}
            centerText={centerText}
            hpxStyle={hpxStyle}
            fixedSize={fixedSize}
            width={width}
            inactive={inactive}
            // @ts-expect-error size is not a number for textarea / FIXME
            size={size}
            paddingRight={paddingRight}
            as={CustomTag}
            inputButtonGroupPosition={inputButtonGroupPosition}
            className={cx('Input-element', customTagClassName)}
            isTextArea={type === 'textarea'}
            ref={resolvedRef}
            onKeyDown={handleKeyDown}
            {...(ariaDescribedBy.length > 0 && { 'aria-describedby': ariaDescribedBy.join(' ') })}
            {...(help && { 'aria-invalid': true })}
            aria-required={required}
          />
          {isActive && (
            <S.InputRight>
              {includeClear && value && (
                <S.InputClear aria-label="Clear input" onClick={handleClear} onKeyDown={handleClearKeyDown}>
                  <img src={IconX} alt="Clear input value" height="12" width="12" />
                </S.InputClear>
              )}
              {includeCancel && (
                <S.InputCancel onClick={handleCancel} onKeyDown={handleCancelKeyDown}>
                  <S.InputCancelText size="tiny">Cancel</S.InputCancelText>
                </S.InputCancel>
              )}
            </S.InputRight>
          )}
        </S.InputContainer>
      ) : (
        <S.Input
          {...filteredProps}
          {...(ariaDescribedBy.length > 0 && { 'aria-describedby': ariaDescribedBy.join(' ') })}
          {...(help && { 'aria-invalid': true })}
          as={CustomTag}
          inputButtonGroupPosition={inputButtonGroupPosition}
          centerText={centerText}
          maxLength={maxLength}
          required={required}
          autoFocus={autoFocus}
          name={name}
          disabled={disabled}
          borderRadius={borderRadius}
          border={border}
          hpxStyle={hpxStyle}
          fixedSize={fixedSize}
          width={width}
          inactive={inactive}
          // @ts-expect-error size is not a number for textarea / FIXME
          size={size}
          type={type}
          value={value}
          className={cx('Input-element', customTagClassName)}
          isTextArea={type === 'textarea'}
          ref={resolvedRef}
          onKeyDown={handleKeyDown}
          aria-required={required}
        />
      )}
      {errorMessage && (
        <StyledAlertText size="sm" aria-live="assertive" role="alert" {...(errorId && { id: errorId })}>
          {errorMessage}
        </StyledAlertText>
      )}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
